import React, { Fragment } from 'react'
import { useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import TextField from '../../core/volunteer/text-field';
import * as Yup from "yup";
import { useFormik } from 'formik';
import agent from '../../../api/agent';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom'; 
import { useEffect } from 'react';

const PersonalInfo = ({ number }) => {
    let { path, url } = useRouteMatch();
    let history = useHistory();
    const title = [
        { value: 'Mr.', label: 'Mr.' },
        { value: 'Ms.', label: 'Ms.' },
        { value: 'Mrs.', label: 'Mrs.' },
    ]

    //React Query Use Mutation...
    const { isLoading, mutate } = useMutation((values) =>
        agent.Participant.register(values), { retry: 2 }
    );
    useEffect(() => {
        
        if (number === null || number === '') {
            history.push('/');
        }
    }, [])
    const formik = useFormik({
        initialValues: {
            Title: '',
            FirstName: "",
            LastName: "",
            MobileNo: '+' + number,
        },
        validationSchema: Yup.object({
            FirstName: Yup.string().required(),
            LastName: Yup.string().required(),
        }),
        validateOnChange: true,
        validateOnBlur: false,
        onSubmit: (values, { resetForm }) => {
            mutate(values, {
                onSuccess: (data) => {
                    resetForm({});
                    // toast.success('Registered Successfully');

                    history.push(`/participant-started/${data}`);

                },
                onError: (error) => {
                    toast.warning(error.data);
                }
            })

        }

    })
    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit}>
                <div className="row">
                    {/* <SelectInput2
                        label={'Title'}
                        sup={'*'}
                        className='mb-2'
                        options={title}
                        field={'Title'}
                        formik={formik}
                    /> */}
                    <TextField
                        label={'First Name'}
                        sup={'*'}
                        placeholder={'ex: John'}
                        name={'FirstName'}
                        {...formik.getFieldProps("FirstName")}
                        className={'mb-2'} />
                    <TextField
                        label={'Last Name'}
                        sup={'*'}
                        placeholder={'ex: Abraham'}
                        name={'LastName'}
                        {...formik.getFieldProps("LastName")}
                        className={'mb-2'} />

                    <div className="col-12 p-2">
                        {!formik.isValid && (
                            <span className="text-danger mt-2">
                                Please fill all the fields
                            </span>
                        )}
                    </div>
                    <div className="col-12">
                        <button
                            disabled={!(formik.isValid && formik.dirty)}
                            type="submit"
                            className='btn btn-primary mt-2 w-100'>
                            {isLoading && (
                                <span
                                    className="spinner-border spinner-border-sm me-3"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            )}
                            Next</button>
                    </div>
                </div>
            </form>
        </Fragment>
    )
}

export default PersonalInfo
