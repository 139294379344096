import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Select from 'react-select'
import TextField2 from '../../core/volunteer/text-field2';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import TextField from '../../core/volunteer/text-field';
import { useMutation } from 'react-query';
import agent from '../../../api/agent';
import { toast } from 'react-toastify';

const VolunteerForgot = ({ setFixUrl }) => {
    let history = useHistory();
    const [code, setCode] = useState('1');
    const [newPassEye, setNewPassEye] = useState(false);
    const [mobile, setMobile] = useState('');
    const [confirmPassEye, setConfirmPassEye] = useState(false);
    const [customValue, setCustomValue] = useState({ label: '+1', values: '1' });
    const [activeKey, setActiveKey] = useState('part1');

    const dropValue = [{ label: '+1', values: '1' }, { label: '+44', values: '44' }]
    const handleChange = (e) => {
        formik.setFieldValue('MobileNo', code + e.target.value);
        setMobile(code + e.target.value);
    }
    const handleSetNumber = (e) => {
        formik2.setFieldValue('MobileNo', mobile);
    }
    const handleTabChange = () => {
        if (activeKey === 'part1') {
            setActiveKey('part2');
        } else if (activeKey === 'part2') {
            setActiveKey('part3');
        }
    }

    const { isLoading, mutate } = useMutation((values) =>
        agent.Volunteer.forgotPasswordSendCode(values), { retry: 2 }
    );
    const formik = useFormik({
        initialValues: {
            MobileNo: "",
        },
        validationSchema: Yup.object({
            MobileNo: Yup.mixed().required(),
        }),
        validateOnChange: true,
        validateOnBlur: false,
        onSubmit: (values, { resetForm }) => {
            mutate(values, {
                onSuccess: (data) => {
                    if (data !== 'No user found')
                        handleTabChange();
                    else
                        toast.warning('No user found associate with this number');
                },
                onError: (error) => {
                    toast.warning(error);
                }
            })
        }
    })
    const { isLoading: isCodeLoading, mutate: codeMutate } = useMutation((values) =>
        agent.Volunteer.forgotVerify(values), { retry: 2 }
    );
    const formik2 = useFormik({
        initialValues: {
            MobileNo: mobile,
            Code: ''
        },
        validationSchema: Yup.object({
            Code: Yup.mixed().required(),
        }),
        validateOnChange: true,
        validateOnBlur: false,
        onSubmit: (values, { resetForm }) => {
            const codeValues = { MobileNo: mobile, Code: formik2.getFieldProps('Code').value }
            codeMutate(codeValues, {
                onSuccess: (data) => {
                    if (data === 'approved')
                        handleTabChange();
                    else
                        toast.warning('Invalid Verification Code');
                },
                onError: (error) => {
                    toast.warning(error);
                }
            })
        }
    })
    const { isLoading: isPassLoading, mutate: passMutate } = useMutation((values) =>
        agent.Volunteer.changePasswordByForgot(values), { retry: 2 }
    );
    const formik3 = useFormik({
        initialValues: {
            MobileNo: formik.getFieldProps('MobileNo').value ? formik.getFieldProps('MobileNo').value : '',
            NewPassword: '',
            ConfirmPassword: '',

        },
        validationSchema: Yup.object({
            NewPassword: Yup.mixed().required(),
            ConfirmPassword: Yup.string()
                .required()
                .oneOf([Yup.ref('NewPassword')], 'Passwords does not match'),
        }),
        validateOnChange: true,
        validateOnBlur: false,
        onSubmit: (values, { resetForm }) => {
            const passValues = { MobileNo: mobile, NewPassword: formik3.getFieldProps('NewPassword').value }
            passMutate(passValues, {
                onSuccess: (data) => {
                    if (data === 'success') {
                        setFixUrl('/dashboard')
                        history.push('/login');
                        toast.success('Password changed successfully');
                    } else
                        toast.success('Failed to change password');
                },
                onError: (error) => {
                    toast.warning(error);
                }
            })
        }
    })
    return (
        <Fragment>

            <div className="col-12 root-div">

                <div className="container">
                    <div className="row">
                        <div className="col-md-2 col-lg-3"></div>
                        <div className="col-md-8 col-lg-6 mt-3 pt-4">
                            <div className="card card-body shadow mt-5 p-md-5">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <h3>Forgot Password</h3>
                                    </div>
                                    <div className="col-lg-1"></div>
                                    <div className="col-lg-12 col-xl-10 mt-3">
                                        <Tabs
                                            activeKey={`${activeKey}`}
                                            transition={false}
                                            id="noanim-tab-example"
                                            className="mb-3"
                                            onSelect={handleTabChange}
                                        >
                                            <Tab eventKey='part1'>
                                                <form onSubmit={formik.handleSubmit}>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h6>Please enter you mobile number</h6>
                                                            <div className="d-flex">
                                                                <Select
                                                                    className={'w-150px'}
                                                                    classNamePrefix='select'
                                                                    isDisabled={false}
                                                                    isLoading={false}
                                                                    isClearable={false}
                                                                    isRtl={false}
                                                                    isSearchable={true}
                                                                    value={customValue && { label: customValue.label, value: customValue.values }}
                                                                    // getOptionLabel={option => option.name}
                                                                    // getOptionValue={option => option.value}
                                                                    options={dropValue}
                                                                    onChange={(obj) => { setCode(obj.values); setCustomValue(obj) }}
                                                                />
                                                                <TextField2
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                        if (event.target.value.length >= 10) {
                                                                            event.preventDefault();
                                                                        }

                                                                    }}
                                                                    onBlur={(e) => handleChange(e)}
                                                                    autoComplete='off'
                                                                    className={'w-100'}
                                                                    placeholder={'XXX XXX XXXX'}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 p-2">
                                                            {!formik.isValid && (
                                                                <span className="text-danger mt-2">
                                                                    Please fill all the fields
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div className="col-12">
                                                            <button
                                                                disabled={!(formik.isValid && formik.dirty)}
                                                                type="submit"
                                                                className='btn btn-primary w-100'>
                                                                {isLoading && (
                                                                    <span
                                                                        className="spinner-border spinner-border-sm me-3"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                )}
                                                                Submit</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </Tab>
                                            <Tab eventKey='part2'>
                                                <form onSubmit={formik2.handleSubmit}>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h6>Enter Verification Code (A verification code has been sent to your mobile number)</h6>
                                                            <div className="d-flex">
                                                                <TextField2
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                        if (event.target.value.length >= 6) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    onBlur={(e) => handleSetNumber(e)}
                                                                    autoComplete='off'
                                                                    className={'w-100'}
                                                                    placeholder={'XXX XXX'}
                                                                    {...formik2.getFieldProps("Code")}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-12 p-2">
                                                            {!formik2.isValid && (
                                                                <span className="text-danger mt-2">
                                                                    Please fill all the fields
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div className="col-12">
                                                            <button
                                                                disabled={!(formik2.isValid && formik2.dirty)}
                                                                type="submit"
                                                                className='btn btn-primary w-100'>
                                                                {isCodeLoading && (
                                                                    <span
                                                                        className="spinner-border spinner-border-sm me-3"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                )}
                                                                Confirm</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </Tab>
                                            <Tab eventKey='part3'>
                                                <form onSubmit={formik3.handleSubmit}>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <TextField
                                                                label={'New Password'}
                                                                type={`${newPassEye ? 'text' : 'password'}`}
                                                                autoComplete='off'
                                                                className={'w-100 eye-text mb-2'}
                                                                placeholder={'New Password'}
                                                                {...formik3.getFieldProps("NewPassword")}
                                                            />
                                                            {
                                                                newPassEye
                                                                    ?
                                                                    <span className='pass-eye cursor-pointer text-muted' onClick={() => setNewPassEye(!newPassEye)}><i className='fas fa-eye'></i></span>
                                                                    :
                                                                    <a className='pass-eye cursor-pointer text-muted top-8px' onClick={() => setNewPassEye(!newPassEye)}><i className='fas fa-eye-slash'></i></a>

                                                            }
                                                        </div>
                                                        <div className="col-12">
                                                            <TextField
                                                                label={'Confirm Password'}
                                                                type={`${confirmPassEye ? 'text' : 'password'}`}
                                                                autoComplete='off'
                                                                className={'w-100 eye-text'}
                                                                placeholder={'Confirm Password'}
                                                                {...formik3.getFieldProps("ConfirmPassword")}
                                                            />
                                                            {
                                                                confirmPassEye
                                                                    ?
                                                                    <span className='pass-eye cursor-pointer text-muted' onClick={() => setConfirmPassEye(!confirmPassEye)}><i className='fas fa-eye'></i></span>
                                                                    :
                                                                    <a className='pass-eye cursor-pointer text-muted' onClick={() => setConfirmPassEye(!confirmPassEye)}><i className='fas fa-eye-slash'></i></a>

                                                            }
                                                        </div>
                                                        <div className="col-12 p-2">
                                                            {!formik3.isValid && (
                                                                <span className="text-danger mt-2">
                                                                    Password mismatch
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div className="col-12">
                                                            <button
                                                                disabled={!(formik3.isValid && formik3.dirty)}
                                                                type="submit"
                                                                className='btn btn-primary w-100'>
                                                                {isPassLoading && (
                                                                    <span
                                                                        className="spinner-border spinner-border-sm me-3"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    ></span>
                                                                )}
                                                                Confirm</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </Tab>
                                        </Tabs>
                                        <div className="col-md-12 text-left">
                                            <Link to='/login' className='nav-link d-inline-flex p-0 pt-2'>Back Login</Link>
                                        </div>
                                        <div className="col-md-12 text-left">
                                            <Link to='/volunteer-registration' className='nav-link  d-inline-flex p-0 pt-2'>Don't have an account? Register now</Link>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-1"></div>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 col-lg-3"></div>
                </div>

            </div>
        </Fragment >
    )
}

export default VolunteerForgot