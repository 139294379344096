import React, { createContext, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { setLinkData } from '../../../redux/user/user.action';
import PersonalInfo from '../../features/participant-registration/personal-info';

const ParticipantLinkRegistration = ({ setLinkData }) => {
    let param = useParams();
    useEffect(() => {
        setLinkData(param.eventcode);
    }, [])
    return (
        <Fragment>
            <div className="col-12 root-div">

                <div className="container">
                    <div className="row">
                        <div className="col-md-2 col-lg-3"></div>
                        <div className="col-md-8 col-lg-6 mt-3 pt-5">
                            <div className="card card-body shadow mt-5 p-md-5">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <h3>GUEST REGISTRATION</h3>
                                    </div>
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-12 col-xl-8 mt-3">
                                        <PersonalInfo number={param.mobileno} />
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {/* <Link to='/' className='nav-link p-0 pt-2 d-contents'>Back to home</Link> */}
                                    </div>
                                    {/* <div className="col-md-12 text-left">
                                        <Link to='/participant-login' className='nav-link p-0 d-contents'>Already have an account? Login</Link>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-lg-3"></div>
                    </div>

                </div>
            </div>
        </Fragment >
    )
}
const mapDispatchToProps = (dispatch) => ({
    setLinkData: (user) => dispatch(setLinkData(user))
})
export default connect(null, mapDispatchToProps)(ParticipantLinkRegistration)
