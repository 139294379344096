import React, { Fragment, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import PrivateRoute from './components/core/private-route';
import Confirmation from './components/features/participant-registration/confirmation';
import ParticipantGetStarted from './components/features/participant-registration/participant-get-started';
import ParticipantInfo from './components/features/participant-registration/participant-info';
import ParticipantTerm from './components/features/participant-registration/participant-term';
import AdminLogin from './components/pages/login/admin-login';
import Login from './components/pages/login/log-in';
import ParticipantLogin from './components/pages/login/participant-login';
import VolunteerForgot from './components/pages/login/volunteer-forgot';
import NotFound from './components/pages/not-found';
import PreLoader from './components/pages/pre-loader';
import ParticipantLinkRegistration from './components/pages/registration/participant-link-registration';
import { selectCurrentUser } from './redux/user/user.selector';
const Index = lazy(() => import('./components/pages/index'));
const PartcipantRegistration = lazy(() => import('./components/pages/registration/participant-registration'));
const VolunteerRegistration = lazy(() => import('./components/pages/registration/volunteer-registration'));
const Dashboard = lazy(() => import('./components/pages/home/dashboard'));

const MainRoute = ({ currentUser, fixUrl, setFixUrl }) => {

    return (
        <Fragment>
            <Suspense fallback={<PreLoader />}>
                <Switch>
                    {
                        currentUser === null ?
                            <Fragment>
                                <Route exact path='/' component={Index}></Route>
                                <Route path='/participant-registration'>
                                    <PartcipantRegistration />
                                </Route>
                                <Route path='/volunteer-registration'>
                                    <VolunteerRegistration />
                                </Route>
                                <Route exact path='/login'>
                                    <Login fixUrl={fixUrl} setFixUrl={setFixUrl} />
                                </Route>
                                <Route exact path='/admin-login'>
                                    <AdminLogin />
                                </Route>
                                <Route exact path='/participant-login'>
                                    <ParticipantLogin />
                                </Route>
                                <Route exact path='/participant-info/:id'>
                                    <ParticipantInfo />
                                </Route>
                                <Route exact path='/participant-link-registration/:mobileno/:eventcode'>
                                    <ParticipantLinkRegistration />
                                </Route>
                                <Route exact path='/participant-terms/:id'>
                                    <ParticipantTerm />
                                </Route>
                                <Route exact path={`/confirmation`}>
                                    <Confirmation />
                                </Route>
                                <Route exact path={`/participant-started/:id`}>
                                    <ParticipantGetStarted />
                                </Route>
                                <Route exact path={`/forgot`}>
                                    <VolunteerForgot setFixUrl={setFixUrl} />
                                </Route>
                                {/* <Route path='*'>
                                    <Login fixUrl={fixUrl} setFixUrl={setFixUrl} />
                                </Route> */}

                            </Fragment>
                            :
                            <PrivateRoute path='/'>
                                <Dashboard setFixUrl={setFixUrl} />
                            </PrivateRoute>
                    }
                </Switch>
            </Suspense>
        </Fragment>
    )
}
const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
})
export default connect(mapStateToProps)(MainRoute);
