import React, { Fragment } from 'react'
import Select from 'react-select';

const SelectInput2 = ({ label, className, field, sup, options, isMulti, formik }) => {
    const changeMethod = (obj) => {
        formik.setFieldValue(field, obj.value);
    }
    return (
        <Fragment>
            <div className="col-12">
                <h6>{label}<sup className='text-danger'>{sup}</sup></h6>
                <Select
                    name={field}
                    className={`${className}`}
                    classNamePrefix='select'
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    isMulti={isMulti}
                    // getOptionLabel={option => option.name}
                    // getOptionValue={option => option.value}
                    options={options}
                    onChange={(obj) => changeMethod(obj)}
                />
            </div>
        </Fragment>
    )
}

export default SelectInput2
