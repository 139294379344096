import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

const Confirmation = () => {
    return (
        <Fragment>
            <div className="col-12 root-div">

                <div className="container">
                    <div className="row">
                        <div className="col-md-2 col-lg-3"></div>
                        <div className="col-md-8 col-lg-6 mt-3 pt-5">
                            <div className="card card-body shadow mt-5 p-md-5">
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <h3>Participant Registration</h3>
                                    </div>
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-12 col-xl-8 mt-3">
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <img src="/dist/img/success.gif" onError={i => i.target.style.display = 'none'} className='w-25' />
                                                <h5 className='mt-4'>Successfully Registered.</h5>
                                                <h4 className='mt-3'>Thanks For Registration.</h4>
                                                <h5 className='mt-3'>You'll get an sms shorly.<br />please wait for a while...</h5>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-lg-3"></div>
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default Confirmation
