import React, { Fragment } from 'react'

const PreLoader = () => {
    return (
        <Fragment>
            <div className="row">
                <div className="col-12 loader h-100vh">
                </div>
            </div>
        </Fragment>
    )
}

export default PreLoader
