import React, { Fragment, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';

const ParticipantGetStarted = () => {
    let params = useParams();
    let location = useLocation();
    return (
        <Fragment>
            <div className="col-12 root-div">
                <div className="container">
                    <div className="row">
                        <div className="col-md-2 col-lg-3"></div>
                        <div className="col-md-8 col-lg-6">
                            <div className="card card-body shadow mt-5 mb-5 p-md-5">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <img src="/dist/img/getStartedPic.png" className='w-100 mb-4' />
                                        <h3 className='mt-2'>COVID-19 TEST REGISTRATION</h3>
                                        <p className="text-justify">
                                            Dear Guest,
                                            <br /> <br />
                                            Greetings! Welcome to the Shared Harvest | myCovidMD™️ COVID-19 Testing Registration Portal.  Thank you for trusting us to serve you.  We aim to exceed expectations.  Our registration process is simple and sweet!  To complete your profile on the next page, please enter the required fields and answer the health screening questions. On the last page, you will have the opportunity to review and sign our Guest Consent Agreement.  Enter your legal name and date of birth to sign.  You will then receive an SMS confirmation of your registration along with your testing location.  That's it, you're all done! We will have a lollipop waiting for you when you arrive at the testing site. (seriously - that's the sweet part!)
                                            <br /> <br />
                                            If you are experiencing COVID-19 symptoms such as fever, chills, cough, shortness of breath, numbness of your tongue, change of taste, headache, body aches, abdominal pain or diarrhea, please do not come to the testing location.  Rather, please call our Community Health Partners (CHP) Coordinator at (323) 880 - 0102 to arrange for alternative testing accommodations.
                                            <br /> <br />
                                            In Unity & Health,
                                            <br /> <br />
                                            Shared Harvest Foundation
                                            https://www.sharedharvestfund.org
                                            IG: @sharedharvestfund
                                        </p>
                                        <Link to={`/participant-info/${params.id}`} className='btn btn-primary btn-block'>Get Started</Link>
                                    </div>
                                </div>
                                <div className="row">
                                    {/* <div className="col-12">
                                        <Link to='/' className='nav-link p-0 pt-2 d-contents'>Back to home</Link>
                                    </div> */}
                                    {/* <div className="col-md-12 text-left">
                        <Link to='/participant-login' className='nav-link p-0 d-contents'>Already have an account? Login</Link>
                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-lg-3"></div>
                    </div>

                </div>
            </div>
        </Fragment >
    )
}

export default ParticipantGetStarted