import axios from "axios";

const envUrl = process.env.REACT_APP_API_URL;
axios.defaults.baseURL = `${envUrl}`;

const responseBody = (response) => response?.data;
const requests = {
  get: (url) => axios.get(url).then(responseBody),
  get: (url, request) => axios.get(url, request).then(responseBody),
  post: (url, body) => axios.post(url, body).then(responseBody),
  put: (url, body) => axios.put(url, body).then(responseBody),
  del: (url) => axios.delete(url).then(responseBody),
  // postForm: (url, values) => {
  //   let formData = new FormData();
  //   Object.keys(values).forEach((key) => {
  //     formData.append(key, values[key]);
  //   });
  //   return axios
  //     .post(url, formData, {
  //       headers: { "Content-type": "multipart/form-data" },
  //     })
  //     .then(responseBody);
  // },
};
const Admin = {
  login: (info) => requests.post(`/loginAdmin`, info),
  changePassword: (info) => requests.post(`/changePassword`, info),
  chnageAdminUsername: (info) => requests.post(`/chnageAdminUsername`, info),
};
const Volunteer = {
  register: (info) => requests.post(`/signUpVolunteer`, info),
  checknumber: (params) => requests.get(`/checkExistVolunteer=${params}`),
  login: (info) => requests.post(`/loginVolunteer`, info),
  question: (info) => requests.post(`/volunteerQuestionary`, info),
  questionList: (params) => requests.get(`/getVolunteerQuestion=${params}`),
  getAllVolunteerWithPagination: (params) =>
    requests.post(`/getAllVolunteerWithPagination`, params),
  totalVolunteerWithLegacy: () =>
    requests.get(`/totalVolunteerWithLegacy`),
  getAllLegacyVolunteerWithPagination: (params) =>
    requests.post(`/getAllLegacyVolunteerWithPagination`, params),
  getAllVolunteerForAppliedDay: (params) =>
    requests.post(`/getAllVolunteerForAppliedDay`, params),
  deleteVolunteer: (info) => requests.post(`/deleteVolunteer`, info),
  volunteerApplyEvent: (info) => requests.post(`/volunteerApplyEvent`, info),
  appliedProject: (params) => requests.post(`/getAppliedProject`, params),
  updateVolunteerFromAdmin: (values) =>
    requests.post("/editVolunteerFromAdmin", values),
  deleteVolunteerCompletely: (values) =>
    requests.post("/deleteVolunteerCompletely", values),
  sendReminderToVolunteer: (info) =>
    requests.post(`/sendReminderToVolunteer`, info),
  forgotPasswordSendCode: (info) =>
    requests.post(`/forgotPasswordSendCode`, info),
  forgotVerify: (info) =>
    requests.post(`/forgotVerify`, info),
  changePasswordByForgot: (info) =>
    requests.post(`/changePasswordByForgot`, info),
};
const Participant = {
  register: (info) => requests.post(`/signUpParticipant`, info),
  checknumber: (params) => requests.get(`/checkExistParticipant=${params}`),
  getAllParticipantWithPagination: (params) =>
    requests.post(`/getAllParticipantWithPagination`, params),
  totalParticipantWithLegacy: () =>
    requests.get(`/totalParticipantWithLegacy`),
  getAllParticipantForCustomMsg: (params) =>
    requests.post(`/getAllParticipantForCustomMsg`, params),
  getRaceLiteral: () => requests.get(`/getRaceLiteral`),
  getEthnicityLiteral: () => requests.get(`/getEthnicityLiteral`),
  participantREGFirstStep: (info) =>
    requests.post(`/participantREGFirstStep`, info),
  participantREGSecondStep: (info) =>
    requests.post(`/participantREGSecondStep`, info),
  participantREGThirdStep: (info) =>
    requests.post(`/participantREGThirdStep`, info),
  participantREGFinalStep: (info) =>
    requests.post(`/participantREGFinalStep`, info),
  sendReminderToParticipant: (info) =>
    requests.post(`/sendReminderToParticipant`, info),
  getParticipantByProject: (params) =>
    requests.post(`/getParticipantByProject`, params),
  deleteParticipantCompletely: (values) =>
    requests.post(`/deleteParticipantCompletely`, values),
  editParticpantInfoFromAdmin: (values) =>
    requests.post(`/editParticpantInfoFromAdmin`, values),
  deleteEverythingWithParticipant: () =>
    requests.post(`/deleteEverythingWithParticipant`),
  getAllParticipantWithPaginationForUpdate: (params) =>
    requests.post(`/getAllParticipantWithPaginationForUpdate`, params),
  getAllLegacyParticipantWithPagination: (params) =>
    requests.post(`/getAllLegacyParticipantWithPagination`, params),
};
const Location = {
  getlocation: () => requests.get(`/getLocation`),
};
const Chat = {
  getRowData: (eventCode) =>
    requests.get(`/getAllParticipantList/${eventCode}`),
  getChatListByEvent: (values) => requests.post(`/getChatListByEvent`, values),
};
const Event = {
  createEvent: (values) => requests.post(`/createNewEvent`, values),
  getAllEvent: (params) => requests.post(`/getAllEvent`, params),
  getAllEventForDropChat: () => requests.get(`/getAllEventForDropChat`),
  getAllEvents: () => requests.get(`/getAllEvent`),
  updateEvent: (values) => requests.post(`/editEventDetails`, values),
  deleteEvent: (value) => requests.del(`/deleteEvent/${value}`),
  sendEvent: (value) => requests.get(`/sendEventToParticipant/${value}`),
  eventSearchByVolunteer: (params) =>
    requests.post(`/eventSearchByVolunteer`, params),
  sendCovidTestResult: (values) =>
    requests.post(`/sendCovidTestResult`, values),
  changeTestTypeFromProject: (values) =>
    requests.post(`/changeTestTypeFromProject`, values),
  changeVolunteerActivities: (values) =>
    requests.post(`/changeVolunteerActivities`, values),
  volunteerLog: (values) => requests.post(`/volunteerLog`, values),
  volunteerActvityLog: (values) =>
    requests.post(`/volunteerActvityLog`, values),
};
const Manager = {
  createNewManager: (values) => requests.post(`/createNewManager`, values),
  getAllManager: (params) => requests.post(`/getAllManager`, params),
  getAllManagerDrop: () => requests.get(`/getAllManager`),
  updateManager: (values) => requests.post(`/editManagerInfo`, values),
  deleteManager: (value) => requests.del(`/deleteManager/${value}`),
};
const Badge = {
  createNewBadge: (values) => requests.post(`/createNewBadge`, values),
  getAllBadge: () => requests.get(`/getAllBadge`),
  getAllBadgeForDrop: () => requests.get(`/getAllBadgeForDrop`),
  updateBadge: (values) => requests.post(`/editBadgeInfo`, values),
  deleteBadge: (value) => requests.del(`/deleteBadge/${value}`),
};
const Activities = {
  createNewActivities: (values) =>
    requests.post(`/createNewActivities`, values),
  getAllActivities: () => requests.get(`/getAllActivities`),
  updateActivity: (values) => requests.post(`/editActivitiesInfo`, values),
  deleteActivities: (value) => requests.del(`/deleteActivities/${value}`),
};
const SDG = {
  createNewSDG: (values) => requests.post(`/createNewSDG`, values),
  getAllSDG: () => requests.get(`/getAllSdg`),
  updateSdgInfo: (values) => requests.post(`/editSdgInfo`, values),
  deleteSdg: (value) => requests.del(`/deleteSdg/${value}`),
};
const Project = {
  createNewProject: (values) => requests.post(`/createNewProject`, values),
  getAllProject: (params) => requests.post(`/getAllProject`, params),
  getAllProjectForVolunteer: (params) =>
    requests.post(`/getAllProjectForVolunteer`, params),
  getAllProjectWithoutParam: () => requests.get(`/getAllProject/`),
  updateProject: (values) => requests.put(`/editNewProject`, values),
  deleteProject: (value) => requests.del(`/deleteProject/${value}`),
  stopDay: (values) => requests.post(`/stopDay`, values),
  startDay: (values) => requests.post(`/startDay`, values),
  sendMsgToVolunteer: (values) => requests.post(`/sendMsgToVolunteer`, values),
};
const Home = {
  individualSdgPoints: (values) =>
    requests.post(`/individualSdgPoints`, values),
  individualActivityPoints: (values) =>
    requests.post(`/individualActivityPoints`, values),
  getAllSdgPointForAdmin: () => requests.get(`/getAllSdgPointForAdmin`),
  getAllDayIndividually: (values) =>
    requests.post(`/getAllDayIndividually`, values),
};
const Twilio = {
  verificationSendCode: (info) => requests.post(`/verificationSendCode`, info),
  VerifyCode: (info) => requests.post(`/VerifyCode`, info),
}
const Export = {
  ExportVolunteerInfo: () => requests.post(`/ExportVolunteerInfo`),
  ExportPaticipantInfo: () => requests.post(`/ExportPaticipantInfo`),
}
export default {
  Home,
  Admin,
  Volunteer,
  Participant,
  Location,
  Chat,
  Event,
  Manager,
  Badge,
  SDG,
  Activities,
  Project,
  Twilio,
  Export,
};
