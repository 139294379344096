import React, { Fragment } from 'react';
import { CheckBox } from '../../core/checkbox';
import * as Yup from 'yup';
import { RadioButton } from '../../core/radio';
import { useFormik } from 'formik';
import agent from '../../../api/agent';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import { Link, useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectLinkData } from '../../../redux/user/user.selector';

const ParticipantTerm = ({ linkData }) => {
    let history = useHistory();
    let params = useParams();

    const permit = [
        'I agree',
    ]
    const { isLoading, mutate } = useMutation((values) =>
        agent.Participant.participantREGFinalStep(values), { retry: 2 }
    );
    const formik = useFormik({
        initialValues: {
            ParticipantId: params.id,
            Agree: "",
            EventCode: linkData ? linkData : '',
        },
        validationSchema: Yup.object({
            Agree: Yup.string().required(),

        }),
        validateOnChange: true,
        validateOnBlur: false,
        onSubmit: (values, { resetForm }) => {
            mutate(values, {
                onSuccess: (data) => {
                    resetForm({});
                    toast.success('Registered Successfully');
                    history.push('/confirmation')
                },
                onError: (error) => {
                    toast.warning(error.data);
                }
            })
        }
    })
    return (
        <Fragment>
            <div className="h-100vh pb-5 overflow-y">
                <div className="row me-0 ms-0">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-6">
                        <div className="card card-body shadow mt-5">
                            <div className="row">
                                <div className="col-12 mt-2 text-center">
                                    <h3><b>GUEST Release and Waiver of Liability Form</b></h3>
                                </div>
                                <div className="col-12 text-justify mt-2">
                                    <form onSubmit={formik.handleSubmit}>
                                        <p>
                                            <b>
                                                This Release and Waiver of Liability (the “release”) releases SHARED HARVEST FOUNDATION & the myCovidMD® initiative, (“Nonprofit”), a nonprofit corporation organized and existing under the laws of the State of California and each of its directors, officers, employees, volunteers, health providers and agents.  The Guest desires to engage in voluntary testing and or vaccination and guidance related to covid screening, testing and contact tracing, follow-up and engage in activities related to the Community Health Partners (CHP) program and other public health collaborative initiatives provided by the myCovidMD initiative as a Voluntary Guest.
                                                <br /><br />
                                                Guests understands that the scope of Guest’s relationship with Nonprofit is limited to a recipient of services that no compensation is expected in return for services provided by Guest; and that the Guest will not hold Shared Harvest Foundation and myCovidMD and affiliate partners liable for any damages to person or property; and the Guest is responsible for his/her own insurance coverage in the event of personal injury or illness as a result of Guest’s services to Nonprofit.
                                                <br /><br />
                                                1.   Waiver and Release: I, the Guest, release and forever discharge and hold harmless Nonprofit and its successors and assigns from any and all liability, claims, and demands of whatever kind of nature, either in law or in a equity, which arise or may hereafter arise from the services I provide to Nonprofit. I understand and acknowledge that this Release discharges Nonprofit from any liability or claim that I may have against Nonprofit with respect to bodily injury, personal injury, illness, death, or property damage that may result from the services I provide to Nonprofit or occurring while I am providing Guest services.
                                                <br /><br />
                                                2.  Insurance: Further I understand that Nonprofit does not assume any responsibility for or obligation to provide me with financial or other assistance, including but not limited to medical, health, or disability benefits or insurance. I expressly waive any such claim for compensation or liability on the part of Nonprofit beyond what may be offered freely by Nonprofit in the event of injury or medical expenses incurred by me.
                                                <br /><br />
                                                3.  Medical Treatment: I hereby Release and forever discharge Nonprofit from any claim whatsoever which arises or may hereafter arise on account of any first-aid treatment or other medical services rendered in connection with an emergency during my tenure as a Guest with Nonprofit.
                                                <br /><br />
                                                4.   Assumption of Risk:  I understand that the services provided to me by the Nonprofit may include activities that are meant to do no harm and are considered non-invasive procedures and limited to pinprick and or nasal swab, and are not inherently dangerous activities. As a Guest, I hereby expressly assume risk of injury or harm from these activities and Release Nonprofit from all liability.
                                                <br /><br />
                                                5.   Photographic Release:  I grant and convey to Nonprofit all right, title, and interest in any and all photographs, images, video, or audio recordings of me or my likeness or voice made by Nonprofit in connection with my participation in this service offered by the Nonprofit at public events only, and I hereby Release and forever discharge Nonprofit from any claim whatsoever which arises from such publicity.
                                                <br /><br />
                                                6.   Other:  As a Guest, I expressly agree that this Release is intended to be as broad and inclusive as permitted by laws of the state of California  and that this Release shall be governed by and interpreted in accordance with the laws of the State of California. I agree that in the event that any clause or provision of this Release is deemed invalid,the enforceability of the remaining provisions of this Release shall not be affected.
                                                <br /><br />
                                                By signing below, I express my understanding and intent to enter into this Release and Waiver of Liability willingly and voluntarily.

                                                <br />
                                                <CheckBox
                                                    label={''}
                                                    name='Agree'
                                                    options={permit}
                                                    formik={formik}
                                                />
                                            </b>
                                        </p>
                                        <button
                                            disabled={!(formik.isValid && formik.dirty)}
                                            type="submit"
                                            className='btn btn-primary mt-2 w-100'>
                                            {isLoading && (
                                                <span
                                                    className="spinner-border spinner-border-sm me-3"
                                                    role="status"
                                                    aria-hidden="true"
                                                ></span>
                                            )}
                                            Confirm Registration</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3"></div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = createStructuredSelector({
    linkData: selectLinkData,
})
export default connect(mapStateToProps, null)(ParticipantTerm)