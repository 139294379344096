import "./App.css";
import React, {
  Fragment,
  lazy,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import MainRoute from "./main-route";
//firebase auth
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { connect } from "react-redux";
import { selectCurrentUser } from "./redux/user/user.selector";
import { createStructuredSelector } from "reselect";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCsBVl1xelEUYQoLWXmidY-oxTK1S1XUuY",
  authDomain: "ngosms-f8b9b.firebaseapp.com",
  projectId: "ngosms-f8b9b",
  storageBucket: "ngosms-f8b9b.appspot.com",
  messagingSenderId: "1018814573253",
  appId: "1:1018814573253:web:a5c48978afcd894703deac",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const App = ({ currentUser }) => {
  const location = useLocation();
  let history = useHistory();
  const [fixUrl, setFixUrl] = useState(null);
  useEffect(() => {
    currentUser === null &&
      location.pathname === "/question" &&
      history.push("/login")
  }, [])
  useEffect(() => {

    (location.pathname === "/" ||
      location.pathname.includes("/volunteer-registration") ||
      location.pathname.includes("/perticipant-registration") ||
      location.pathname.includes("/login")) ?
      currentUser !== null &&
      (currentUser.UserType === "volunteer"
        ? history.push("/dashboard")
        : currentUser.UserType === "admin" && history.push("/dashboard"))
      :
      setFixUrl(location.pathname);
    // (currentUser.UserType === 'volunteer') ?
    // ()
    // (history.push('/dashboard')) 
  }, [location]);
  return (
    <Fragment>
      <MainRoute fixUrl={fixUrl} setFixUrl={setFixUrl} />
    </Fragment>
  );
};
const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});
export default connect(mapStateToProps)(App);
