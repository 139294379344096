import React, { Fragment, useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useMutation, useQuery } from 'react-query';
import agent from '../../../api/agent';
import SelectInput2 from '../../core/volunteer/select-input-2';
import TextArea from '../../core/volunteer/text-area';
import TextField from '../../core/volunteer/text-field';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { RadioButton } from '../../core/radio';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import SelectInput4 from '../../core/volunteer/select-input-4';
import SelectInput from '../../core/volunteer/select-input';

const ParticipantInfo = () => {
    let param = useParams();
    let history = useHistory();
    const [activeKey, setActiveKey] = useState('part1');
    const [testType, setTestType] = useState('Antigen');
    const { data: raceData, refetch: raceRefetch } = useQuery(["race"], () => agent.Participant.getRaceLiteral(), {
        refetchOnWindowFocus: false,//turned off on window forcus refetch option
        enabled: false // turned off by default, manual refetch is needed
    });
    const { data: ethnicityData, refetch: ethnicityRefetch } = useQuery(["ethnicity"], () => agent.Participant.getEthnicityLiteral(), {
        refetchOnWindowFocus: false,//turned off on window forcus refetch option
        enabled: false // turned off by default, manual refetch is needed
    });
    useEffect(() => {
        raceRefetch();
        ethnicityRefetch();
        console.log(raceData);
    }, []);
    useEffect(() => {
        document.querySelector('.nav-tabs').style.display = 'none';
    }, [])
    const { isLoading: firstLoading, mutate: firstMutate } = useMutation((values) =>
        agent.Participant.participantREGFirstStep(values), { retry: 2 }
    );
    const { isLoading: secondLoading, mutate: secondMutate } = useMutation((values) =>
        agent.Participant.participantREGSecondStep(values), { retry: 2 }
    );
    const { isLoading: thirdLoading, mutate: thirdMutate } = useMutation((values) =>
        agent.Participant.participantREGThirdStep(values), { retry: 2 }
    );
    const covidTest = [
        { value: 'PCR', label: 'PCR' },
        { value: 'Antigen', label: 'Antigen' },
        { value: 'Antibody', label: 'Antibody' },
        { value: 'PCR_Antigen', label: 'PCR_Antigen' },
        { value: 'PCR + Antibody', label: 'PCR + Antibody' },
    ]
    const gender = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
        { value: 'Non-binary', label: 'Non-binary' },
        { value: 'Other', label: 'Other' },
        { value: 'Unknown', label: 'Unknown' },
    ]
    const billing = [
        { value: 'Insurance', label: 'Insurance' },
        { value: 'No Insurance', label: 'No Insurance' },
        { value: 'Private Event', label: 'Private Event' },
    ]
    const radioOption = [
        'Yes',
        'No',
        'Unknown',
    ]
    const confirmSMS = [
        'Yes',
        'No'
    ]
    const InsuranceCompany = [
        { value: '_Cares Act (Uninsured)', label: '_Cares Act (Uninsured)' },
        { value: 'Aetna', label: 'Aetna' },
        { value: 'Anthem', label: 'Anthem' },
        { value: 'Blue Cross', label: 'Blue Cross' },
        { value: 'Blue Shield', label: 'Blue Shield' },
        { value: 'Cigna', label: 'Cigna' },
        { value: 'Humana', label: 'Humana' },
        { value: 'Kaiser', label: 'Kaiser' },
        { value: 'Medicare', label: 'Medicare' },
        { value: 'Oscar', label: 'Oscar' },
        { value: 'Other', label: 'Other' },
        { value: 'United Healthcare', label: 'United Healthcare' },
    ]
    const handleTabChange = () => {
        if (activeKey === 'part1') {
            setActiveKey('part2');
        } else if (activeKey === 'part2') {
            setActiveKey('part3');
        }
    }
    const handlePrevChange = () => {
        if (activeKey === 'part2') {
            setActiveKey('part1');
        } else if (activeKey === 'part3') {
            setActiveKey('part2');
        }
    }
    const formik = useFormik({
        initialValues: {
            ParticipantId: param.id,
            CovidTest: 'Antigen',
            Email: "",
            DOB: "",
            Gender: "",
            Race: "",
            Ethnicity: "",
            Address: "",
            City: "",
            State: "",
            Zip: "",
        },
        validationSchema: Yup.object({
            CovidTest: Yup.mixed().required(),
            Email: Yup.mixed().required(),
            Gender: Yup.mixed().required(),
            Address: Yup.mixed().required(),
            Race: Yup.mixed().required(),
            Ethnicity: Yup.mixed().required(),
            City: Yup.mixed().required(),
            State: Yup.mixed().required(),
            Zip: Yup.mixed().required(),
        }),
        validateOnChange: true,
        validateOnBlur: false,
        onSubmit: (values, { resetForm }) => {
            console.log(values);
            firstMutate(values, {
                onSuccess: (data) => {
                    handleTabChange();
                    // resetForm({});
                    // toast.success('Badge added Successfully');
                    // history.push('/badges')
                },
                onError: (error) => {
                    toast.warning(error.data);
                }
            })
        }
    })
    const formik2 = useFormik({
        initialValues: {
            ParticipantId: param.id,
            BillingType: "",
            InsuranceCompany: '_Cares Act (Uninsured)',
            PolicyNumber: "",
            GroupNumber: "",
            FirstNamePrimaryInsured: "",
            LastNamePrimaryInsured: "",
            RelationShip: "",
        },
        validationSchema: Yup.object({
            BillingType: Yup.mixed().required(),
        }),
        validateOnChange: true,
        validateOnBlur: false,
        onSubmit: (values, { resetForm }) => {
            console.log(values);
            if (values.BillingType === 'Insurance') {
                if (values.InsuranceCompany === '') {
                    toast.error('Insurance company is required');
                } else {
                    secondMutate(values, {
                        onSuccess: (data) => {
                            // resetForm({});
                            handleTabChange();
                        },
                        onError: (error) => {
                            toast.warning(error.data);
                        }
                    })
                }
            } else {
                secondMutate(values, {
                    onSuccess: (data) => {
                        // resetForm({});
                        handleTabChange();
                    },
                    onError: (error) => {
                        toast.warning(error.data);
                    }
                })
            }
        }
    })
    const formik3 = useFormik({
        initialValues: {
            ParticipantId: param.id,
            FirstTest: "",
            EmployeeHealthCare: "",
            Symptomatic: "",
            DataOfOnSet: "",
            Hospitalized: "",
            ICU: "",
            CongregateCare: "",
            Pregnant: "",
            ConfirmationSMS: 'Yes',
        },
        validationSchema: Yup.object({
            FirstTest: Yup.mixed().required(),
            EmployeeHealthCare: Yup.mixed().required(),
            Symptomatic: Yup.mixed().required(),
            Hospitalized: Yup.mixed().required(),
            CongregateCare: Yup.mixed().required(),
            Pregnant: Yup.mixed().required(),
        }),
        validateOnChange: true,
        validateOnBlur: false,
        onSubmit: (values, { resetForm }) => {
            if (values.Symptomatic === 'Yes') {
                if (values.DataOfOnSet === '') {
                    toast.error('Date on set is required');
                } else {

                    thirdMutate(values, {
                        onSuccess: (data) => {
                            // resetForm({});
                            // toast.success('Badge added Successfully');
                            history.push(`/participant-terms/${param.id}`);
                        },
                        onError: (error) => {
                            toast.warning(error.data);
                        }
                    })
                }
            } else {
                thirdMutate(values, {
                    onSuccess: (data) => {
                        resetForm({});
                        // toast.success('Badge added Successfully');
                        history.push(`/participant-terms/${param.id}`);
                    },
                    onError: (error) => {
                        toast.warning(error.data);
                    }
                })
            }
        }
    })
    return (
        <Fragment>
            <div className="h-100vh pb-5 overflow-y">
                <div className="row me-0 ms-0">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-6">
                        <div className="card card-body shadow mt-5">
                            <Tabs
                                activeKey={`${activeKey}`}
                                transition={false}
                                id="noanim-tab-example"
                                className="mb-3"
                                onSelect={handleTabChange}
                            >
                                <Tab eventKey='part1'>
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <h3>GUEST INFO</h3>
                                        </div>
                                    </div>
                                    <hr className='mt-0' />
                                    <div className="row">
                                        <form onSubmit={formik.handleSubmit}>
                                            <SelectInput
                                                label={'Select the COVID-19 test needed'}
                                                sup={'*'}
                                                field={'CovidTest'}
                                                options={covidTest}
                                                className='mb-2'
                                                setValue={setTestType}
                                                values={testType}
                                                closeMenuOnSelect={false}
                                                formik={formik}
                                            />

                                            <TextField
                                                label={'Email'}
                                                sup={'*'}
                                                type='email'
                                                placeholder={'name@example.com'}
                                                className='mb-2'
                                                name={'Email'}
                                                {...formik.getFieldProps('Email')}
                                            />
                                            <TextField
                                                label={'Date of Birth'}

                                                type='date'
                                                className='mb-2'
                                                name={'DOB'}
                                                {...formik.getFieldProps('DOB')}
                                            />
                                            <SelectInput2
                                                label={'Gender'}
                                                sup={'*'}
                                                options={gender}
                                                className='mb-2'
                                                formik={formik}
                                                field={'Gender'}
                                            />
                                            <SelectInput2
                                                label={'Race'}
                                                sup={'*'}
                                                options={ethnicityData}
                                                className='mb-2'
                                                formik={formik}
                                                field={'Race'}
                                            />
                                            <SelectInput2
                                                label={'Ethnicity'}
                                                sup={'*'}
                                                options={raceData}
                                                className='mb-2'
                                                formik={formik}
                                                field={'Ethnicity'}
                                            />
                                            <TextArea
                                                label={'Address'}
                                                sup={'*'}

                                                placeholder='Enter your full address'
                                                style={{ mindHeight: '150px' }}
                                                name={'Address'}
                                                {...formik.getFieldProps('Address')}
                                            />
                                            <TextField
                                                label={'City'}
                                                sup={'*'}
                                                className='mb-2'
                                                name={'City'}
                                                placeholder='City'
                                                {...formik.getFieldProps('City')}

                                            />
                                            <TextField
                                                label={'State'}
                                                sup={'*'}
                                                className='mb-2'
                                                name={'State'}
                                                placeholder='State'
                                                {...formik.getFieldProps('State')}

                                            />

                                            <TextField
                                                label={'Zip Code'}
                                                sup={'*'}
                                                placeholder='XXXXX'
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                name={'Zip'}
                                                {...formik.getFieldProps('Zip')}
                                            />
                                            <div className="col-12 mt-4 text-right">
                                                <button
                                                    disabled={!(formik.isValid && formik.dirty)}
                                                    type="submit"
                                                    className='btn btn-primary mt-2 w-100'
                                                // {isLoading && (
                                                //     <span
                                                //         className="spinner-border spinner-border-sm me-3"
                                                //         role="status"
                                                //         aria-hidden="true"
                                                //     ></span>
                                                // )}
                                                // onClick={(e) => handlePart1(e)}
                                                >
                                                    Next</button>
                                            </div>
                                        </form>
                                    </div>
                                </Tab>
                                <Tab eventKey='part2'>
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <h3>GUEST INFO</h3>
                                        </div>
                                    </div>
                                    <hr className='mt-0' />
                                    <div className="row">
                                        <div className="col-12 text-left mb-2">
                                            <button className="btn btn-success" onClick={handlePrevChange}>
                                                <i className="fas fa-chevron-left"></i>
                                            </button>
                                        </div>
                                        <form onSubmit={formik2.handleSubmit}>

                                            <SelectInput2
                                                label={'Guest Payment Information'}
                                                sup={'*'}
                                                options={billing}
                                                className={'mb-2'}
                                                formik={formik2}
                                                field={'BillingType'}
                                            />
                                            {
                                                formik2.getFieldProps('BillingType').value === 'Insurance'
                                                &&
                                                <SelectInput2
                                                    label={'Insurance Company'}
                                                    sup={'*'}
                                                    options={InsuranceCompany}
                                                    className={'mb-2'}
                                                    formik={formik2}
                                                    field={'InsuranceCompany'}
                                                />

                                            }
                                            < TextField
                                                label={'Policy Number'}

                                                type={'text'}
                                                placeholder={'XXXX'}
                                                className='mb-2'
                                                name={'PolicyNumber'}
                                                {...formik2.getFieldProps('PolicyNumber')}
                                            />
                                            <TextField
                                                label={'Group Number'}

                                                type={'text'}
                                                placeholder={'XXXX'}
                                                className='mb-2'
                                                name={'GroupNumber'}
                                                {...formik2.getFieldProps('GroupNumber')}
                                            />
                                            <TextField
                                                label={'First Name of Primary Insured'}

                                                type={'text'}
                                                placeholder={'XXXX'}
                                                className='mb-2'
                                                name={'FirstNamePrimaryInsured'}
                                                {...formik2.getFieldProps('FirstNamePrimaryInsured')}
                                            />
                                            <TextField
                                                label={'Last Name of Primary Insured'}

                                                type={'text'}
                                                placeholder={'XXXX'}
                                                className='mb-2'
                                                name={'LastNamePrimaryInsured'}
                                                {...formik2.getFieldProps('LastNamePrimaryInsured')}
                                            />
                                            <TextField
                                                label={'Relationship to Patient'}

                                                type={'text'}
                                                placeholder={'XXXXXX'}
                                                className='mb-2'
                                                name={'RelationShip'}
                                                {...formik2.getFieldProps('RelationShip')}
                                            />
                                            <div className="col-12 mt-4 text-right">
                                                <button
                                                    disabled={!(formik2.isValid && formik2.dirty)}
                                                    type="submit"
                                                    className='btn btn-primary mt-2 w-100'
                                                // {isLoading && (
                                                //     <span
                                                //         className="spinner-border spinner-border-sm me-3"
                                                //         role="status"
                                                //         aria-hidden="true"
                                                //     ></span>
                                                // )}
                                                // onClick={(e) => handlePart2(e)}
                                                >
                                                    Next</button>
                                            </div>
                                        </form>
                                    </div>
                                </Tab>
                                <Tab eventKey='part3'>
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <h3>HEALTH SCREENING</h3>
                                        </div>
                                    </div>
                                    <hr className='mt-0' />
                                    <div className="row">
                                        <div className="col-12 text-left mb-2">
                                            <button className="btn btn-success" onClick={handlePrevChange}>
                                                <i className="fas fa-chevron-left"></i>
                                            </button>
                                        </div>
                                        <form onSubmit={formik3.handleSubmit}>
                                            <RadioButton
                                                label={'Have you had a positive COVID-19 test in the last 90 days?'}
                                                sup={'*'}
                                                name={'FirstTest'}
                                                options={radioOption}
                                                formik={formik3}
                                            />
                                            <RadioButton
                                                label={'Do you work in a health-related field or hospital setting?'}
                                                sup={'*'}
                                                name={'EmployeeHealthCare'}
                                                options={radioOption}
                                                formik={formik3}
                                            />
                                            <RadioButton
                                                label={'Do you have any symptoms as defined by the CDC?'}
                                                sup={'*'}
                                                name={'Symptomatic'}
                                                options={radioOption}
                                                formik={formik3}
                                            />
                                            {
                                                formik3.getFieldProps('Symptomatic').value === 'Yes' &&
                                                <TextField
                                                    type='date'
                                                    sup={'*'}
                                                    name={'DataOfOnSet'}
                                                    label={'Provide date of symptoms onset (If "Yes" to symptomatic)'}
                                                    {...formik3.getFieldProps('DataOfOnSet')}
                                                    className='mb-2'
                                                />
                                            }

                                            <RadioButton
                                                label={'Have you been hospitalized in the last 14 days (COVID-19 related or not)?'}
                                                sup={'*'}
                                                name={'Hospitalized'}
                                                options={radioOption}
                                                formik={formik3}
                                            />
                                            {/* <RadioButton
                                                label={'Were you in the ICU?'}
                                                sup={'*'}
                                                name={'ICU'}
                                                options={radioOption}
                                                formik={formik3}
                                            /> */}
                                            <RadioButton
                                                label={'Are you a resident in a congregate care setting (including nursing homes, residential care for people with intellectual and developmental disabilities, psychiatric treatment facilities, group homes, board and care homes, homeless shelter, foster care or other setting)?'}
                                                sup={'*'}
                                                name={'CongregateCare'}
                                                options={radioOption}
                                                formik={formik3}
                                            />
                                            <RadioButton
                                                label={'Are you currently pregnant? '}
                                                sup={'*'}
                                                name={'Pregnant'}
                                                options={radioOption}
                                                formik={formik3}
                                            />
                                            <RadioButton
                                                label={'Confirm you are happy to be connected via SMS about your COVID result'}
                                                sup={'*'}
                                                name={'ConfirmationSMS'}
                                                options={confirmSMS}
                                                formik={formik3}
                                            />
                                            <button
                                                disabled={!(formik3.isValid && formik3.dirty)}
                                                type="submit"
                                                className='btn btn-primary mt-2 w-100'
                                            // {isLoading && (
                                            //     <span
                                            //         className="spinner-border spinner-border-sm me-3"
                                            //         role="status"
                                            //         aria-hidden="true"
                                            //     ></span>
                                            // )}
                                            // onClick={(e) => handlePart3(e)}
                                            >
                                                Confirm Registration</button>
                                        </form>
                                    </div>
                                </Tab>
                            </Tabs>
                            {/* <div className="row">
                                <div className="col-12 mt-2">
                                    <Link to='/' className='nav-link p-0 pt-2 d-contents'>Back to home</Link>

                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="col-lg-3"></div>
                </div>
            </div>
        </Fragment >
    )
}

export default ParticipantInfo;