import { useFormik } from 'formik';
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import TextField2 from '../../core/volunteer/text-field2';
import * as Yup from "yup";
import { useMutation } from 'react-query';
import agent from '../../../api/agent';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { setCurrentUser } from '../../../redux/user/user.action';
import Select from 'react-select';

const Login = ({ setCurrentUser, fixUrl, setFixUrl }) => {
    let history = useHistory();
    const [code, setCode] = useState('1');
    const [customValue, setCustomValue] = useState({ label: '+1', values: '1' });

    const dropValue = [{ label: '+1', values: '1' }, { label: '+44', values: '44' }]
    const handleChange = (e) => {
        formik.setFieldValue('MobileNo', code + e.target.value);

    }
    const { isLoading, mutate } = useMutation((values) =>
        agent.Volunteer.login(values), { retry: 2 }
    );

    const formik = useFormik({
        initialValues: {
            MobileNo: "",
            Password: "",
        },
        validationSchema: Yup.object({
            MobileNo: Yup.mixed().required(),
            Password: Yup.mixed().required(),
        }),
        validateOnChange: true,
        validateOnBlur: false,
        onSubmit: (values, { resetForm }) => {
            mutate(values, {
                onSuccess: (data) => {

                    if (data === 'Incorrect password') {
                        toast.error('Password Mismatch');
                    } else if (data === 'not exist') {
                        toast.error('User doesn\'t exist');
                    } else {
                        setCurrentUser(data[0]);
                        if (data[0].UserType === 'volunteer') {
                            fixUrl === null ?
                                history.push(`/dashboard`)
                                :
                                history.push(fixUrl)
                        }
                    }
                },
                onError: (error) => {
                    toast.warning(error);
                }
            })
        }
    })
    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit}>
                <div className="col-12 root-div">

                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 col-lg-3"></div>
                            <div className="col-md-8 col-lg-6 mt-3 pt-4">
                                <div className="card card-body shadow mt-5 p-md-5">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <h3>Log in</h3>
                                        </div>
                                        <div className="col-lg-1"></div>
                                        <div className="col-lg-12 col-xl-10 mt-3">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h6>Mobile Number</h6>
                                                    <div className="d-flex">
                                                        <Select
                                                            className={'w-150px'}
                                                            classNamePrefix='select'
                                                            isDisabled={false}
                                                            isLoading={false}
                                                            isClearable={false}
                                                            isRtl={false}
                                                            isSearchable={true}
                                                            value={customValue && { label: customValue.label, value: customValue.values }}
                                                            // getOptionLabel={option => option.name}
                                                            // getOptionValue={option => option.value}
                                                            options={dropValue}
                                                            onChange={(obj) => { setCode(obj.values); setCustomValue(obj) }}
                                                        />
                                                        <TextField2
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                                if (event.target.value.length >= 10) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onChange={(e) => handleChange(e)}
                                                            autoComplete='off'
                                                            className={'w-100'}
                                                            placeholder={'XXX XXX XXXX'}
                                                        // {...formik.getFieldProps("MobileNo")}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <h6>Password</h6>
                                                    <TextField2
                                                        type={'password'}
                                                        placeholder={'*********'}
                                                        {...formik.getFieldProps("Password")} />
                                                </div>
                                                <div className="col-md-12 text-right">
                                                    <Link to='/forgot' className='nav-link p-0 pt-2'>Forgot Password</Link>
                                                </div>
                                                <div className="col-12 p-2">
                                                    {!formik.isValid && (
                                                        <span className="text-danger mt-2">
                                                            Please fill all the fields
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="col-12">
                                                    <button
                                                        disabled={!(formik.isValid && formik.dirty)}
                                                        type="submit"
                                                        className='btn btn-primary w-100'>
                                                        {isLoading && (
                                                            <span
                                                                className="spinner-border spinner-border-sm me-3"
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span>
                                                        )}
                                                        Log in</button>
                                                </div>
                                                <div className="col-md-12 text-left">
                                                    <Link to='/' className='nav-link p-0 pt-2'>Back Home</Link>
                                                </div>
                                                <div className="col-md-12 text-left">
                                                    <Link to='/volunteer-registration' className='nav-link p-0 pt-2'>Don't have an account? Register now</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-1"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-2 col-lg-3"></div>
                        </div>

                    </div>
                </div>
            </form>
        </Fragment >
    );
};

const mapDispatchToProps = (dispatch) => ({
    setCurrentUser: (user) => dispatch(setCurrentUser(user))
})
export default connect(null, mapDispatchToProps)(Login);
