import { useFormik } from 'formik';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import TextField2 from '../../core/volunteer/text-field2';
import * as Yup from "yup";
import { useMutation } from 'react-query';
import agent from '../../../api/agent';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { setCurrentUser } from '../../../redux/user/user.action';

const AdminLogin = ({ setCurrentUser }) => {
    let history = useHistory();

    const { isLoading, mutate } = useMutation((values) =>
        agent.Admin.login(values), { retry: 2 }
    );

    const formik = useFormik({
        initialValues: {
            Email: "",
            Password: "",
        },
        validationSchema: Yup.object({
            Email: Yup.mixed().required(),
            Password: Yup.mixed().required(),
        }),
        validateOnChange: true,
        validateOnBlur: false,
        onSubmit: (values, { resetForm }) => {
            mutate(values, {
                onSuccess: (data) => {
                    if (data === 'Incorrect password') {
                        toast.error('Password Mismatch');
                    } else if (data === 'not exist') {
                        toast.error('User doesn\'t exist');
                    } else {
                        setCurrentUser(data[0]);
                        if (data[0].UserType === 'admin') {
                            history.push(`/dashboard`)
                        }
                    }
                },
                onError: (error) => {
                    toast.warning(error);
                }
            })
        }
    })
    return (
        <Fragment>
            <form onSubmit={formik.handleSubmit}>
                <div className="col-12 root-div">

                    <div className="container">
                        <div className="row">
                            <div className="col-md-2 col-lg-3"></div>
                            <div className="col-md-8 col-lg-6 mt-3 pt-4">
                                <div className="card card-body shadow mt-5 p-md-5">
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <h3>Admin Log in</h3>
                                        </div>
                                        <div className="col-lg-1"></div>
                                        <div className="col-lg-12 col-xl-10 mt-3">
                                            <div className="row">
                                                <div className="col-12">
                                                    <h6>Email or Username</h6>
                                                    <TextField2 placeholder={'name@example.com'}
                                                        {...formik.getFieldProps("Email")}
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <h6>Password</h6>
                                                    <TextField2 type={'password'} placeholder={'*********'}
                                                        {...formik.getFieldProps("Password")} />
                                                </div>
                                                <div className="col-12 p-2">
                                                    {!formik.isValid && (
                                                        <span className="text-danger mt-2">
                                                            Please fill all the fields
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="col-12">
                                                    <button
                                                        disabled={!(formik.isValid && formik.dirty)}
                                                        type="submit"
                                                        className='btn btn-primary mt-2 w-100'>
                                                        {isLoading && (
                                                            <span
                                                                className="spinner-border spinner-border-sm me-3"
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span>
                                                        )}
                                                        Log in</button>
                                                </div>
                                                <div className="col-md-12 text-left">
                                                    <Link to='/' className='nav-link p-0 pt-2'>Back Home</Link>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-lg-1"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-2 col-lg-3"></div>
                        </div>

                    </div>
                </div>
            </form>
        </Fragment>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setCurrentUser: (user) => dispatch(setCurrentUser(user))
})
export default connect(null, mapDispatchToProps)(AdminLogin);
